import { tw } from '@/utils/tw';
import BaseArticlePaywallForm from 'base/components/standalone/ArticlePaywall/Form/theme';

const ArticlePaywallFormTheme = tw.theme({
  extend: BaseArticlePaywallForm,
  slots: {
    policy: ['text-content-sm', '[&>a]:underline', '[&>a]:text-summer-red-700'],
    caption: ['text-content-sm', '[&>a]:underline', '[&>a]:text-summer-red-700'],
    provider: ['text-content-sm'],
  },
});

export default Object.assign(ArticlePaywallFormTheme, {
  TextField: BaseArticlePaywallForm.TextField,
  Button: BaseArticlePaywallForm.Button,
});
